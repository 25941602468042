<template>
  <div>
    <div class="container">
      <div class="list list3">
        <div class="listitem1">
          <div class="listbox">
            <div class="name"><span class="red">*</span>人像近照</div>
            <div class="unames">正对手机，保持图像</div>
            <div class="unames1">清晰，光线充足。</div>
          </div>
          <div class="listbox1">
            <img :src="jzimgurl" @click="photograph_jz" class="iconkg" />
          </div>
          
        </div>
      </div>
      <div class="list list2">
        <div class="listitem1">
          <div class="listbox">
            <div class="name">车牌照片</div>
            <div class="unames">{{number_car}}</div>
            <div class="unames1">{{txt_car}}</div>
          </div>
          <div class="listbox1">
            <img :src="cpimgurl" @click="photograph_cp" class="iconkg" />
          </div>
          
        </div>
      </div>
      <div class="list_ll list2">
        <div class="ll">
          <div class="button primary mt" @click="next">下一步</div>         
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  async created() {
    /*let { hxflage,wxdj_uuid,number_car,picture,picture_car,gxy,tnb,xnxgjb,xc } = this.$route.query;
    this.wxdj_uuid = wxdj_uuid;
    //alert(hxflage);
    if(hxflage==='1'){
      this.number_car = number_car;
      this.picture = picture;
      this.picture_car = picture_car;
      this.gxy = gxy;
      this.tnb = tnb;
      this.xnxgjb = xnxgjb;
      this.xc = xc; 
      this.jzimgurl = "http://public.image.ywkj.online/"+this.picture;
      if (!isEmpty(this.picture_car)) {
        this.txt_car = '';
        this.cpimgurl = "http://public.image.ywkj.online/"+this.picture_car;  
      }        
    }*/

    let { wxdj_uuid,mobile,hrefurl } = this.$route.query;
    this.wxdj_uuid = wxdj_uuid;
    this.hrefurl = hrefurl;
    //alert(mobile);
    let { data } = await this.axios.post(
      '/ctid/TlZhService/pz_tjdhh',
      qs.stringify({
        wxdj_uuid: this.wxdj_uuid,
        mobile: mobile
      })
    );
    if (data.flag === '0') {
      this.number_car = data.data.number_car;
      this.picture = data.data.picture;
      this.picture_car = data.data.picture_car;
      this.gxy = data.data.gxy;
      this.tnb = data.data.tnb;
      this.xnxgjb = data.data.xnxgjb;
      this.xc = data.data.xc; 
      this.jzimgurl = this.picture;
      if (!isEmpty(this.picture_car)) {
        this.txt_car = '';
        this.cpimgurl = this.picture_car;  
      }
    }

    let url = '';
    let ua = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(ua)) {
      //url = location.href.split('#')[0];
      url = this.hrefurl;
      //alert("iphone"+url);
    }else{
      url = location.href;
      //alert("android:"+url);
    }

    let config = (
      await this.axios.post(
        '/ctid/testService/jsapisign',
        qs.stringify({
          url: url
        })
      )
    ).data.data;
    window.wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: config.appid, // 必填，公众号的唯一标识
      timestamp: config.timestamp, // 必填，生成签名的时间戳 ${wxConfig.timestamp}
      nonceStr: config.nonce_str, // 必填，生成签名的随机串 ${wxConfig.nonceStr}
      signature: config.signature, // 必填，签名，见附录1 ${wxConfig.signature}
      jsApiList: [// 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              'checkJsApi',
							'scanQRCode',
							'getLocation', 
							'chooseImage',//拍照或从手机相册中选图接口
							'previewImage',//预览图片接口
							'uploadImage',//上传图片接口
							'downloadImage',//下载图片接口
							'getLocalImgData'
        ] 
    });

  },
  data() {
    return {
      data: {
        kgflag: false
      },
      jzimgurl:'/img/jz.png',
      cpimgurl:'/img/cp.png',
      gxy:'2',
      tnb:'2',
      xnxgjb:'2',
      xc:'2',
      wxdj_uuid:'',
      picture_car:'',
      picture:'',
      number_car:'保持车牌号清晰可见，',
      txt_car:'光线充足。',
      hrefurl:'',
      rlflag: false,
      flag: false
    };
  },
  methods: {
    photograph_jz(){
      window.wx.ready(() => {
        //选择照片
        window.wx.chooseImage({
          count: 1, //张数， 默认9
          sizeType: ['compressed'], //建议压缩图
          sourceType: ['album', 'camera'], // 来源是相册(album)、相机(camera)
          //sourceType: ['camera'], // 来源是相册(album)、相机(camera)
          success: res => {
            var localIds = res.localIds.toString();

            //上传至微信服务器
            window.wx.uploadImage({
              localId: localIds, // 需要上传的图片的本地ID，由chooseImage接口获得
              success: res => {
                var serverId = res.serverId; // 返回图片的服务器端ID     
                //alert(serverId);     
                this.uploadImg_jz(serverId);
              },
              fail: function() {
                Toast("上传图片异常");
              }
            });
          },
          fail: function() {
            Toast("选择图片异常");
          }
        });  
      });
      
    },
    async uploadImg_jz(serverId) {
      //alert("uploadImg");
      let { data } = await this.axios.post(
        '/ctid/TlZhService/pz_jz',
        qs.stringify({
          wxdj_uuid: this.wxdj_uuid,
          pzjzid: serverId
        })
      );
      if (data.flag === '0') {
        Toast(data.message);
        //this.rlflag = true;
        this.picture = data.data.picture;
        //this.jzimgurl = "http://public.image.ywkj.online/"+data.data.picture;
        this.jzimgurl = data.data.picture;
      } else if (data.flag === '3') {
        Dialog({ message: "<img src='/img/sb.png' width=100px height=60px/></br>"+data.message });
        this.$router.replace('/assistregister/register_1?hrefurl='+this.hrefurl);
      } else {
        //Toast(data.message);
        Dialog({ message: "<img src='/img/sb.png' width=100px height=60px/></br>"+data.message });
      }     
    },
    photograph_cp(){
      window.wx.ready(() => {
        //选择照片
        window.wx.chooseImage({
          count: 1, //张数， 默认9
          sizeType: ['compressed'], //建议压缩图
          sourceType: ['album', 'camera'], // 来源是相册(album)、相机(camera)
          //sourceType: ['camera'], // 来源是相册(album)、相机(camera)
          success: res => {
            var localIds = res.localIds.toString();

            //上传至微信服务器
            window.wx.uploadImage({
              localId: localIds, // 需要上传的图片的本地ID，由chooseImage接口获得
              success: res => {
                var serverId = res.serverId; // 返回图片的服务器端ID     
                //alert(serverId);     
                this.uploadImg_cp(serverId);
              },
              fail: function() {
                Toast("上传图片异常");
              }
            });
          },
          fail: function() {
            Toast("选择图片异常");
          }
        }); 
      }); 
      
    },
    async uploadImg_cp(serverId) {
      //alert("uploadImg");
      let { data } = await this.axios.post(
        '/ctid/TlZhService/pz_cp',
        qs.stringify({
          wxdj_uuid: this.wxdj_uuid,
          pzcpid: serverId
        })
      );
      if (data.flag === '0') {
        Toast(data.message);
        this.number_car = data.data.number_car;
        this.txt_car = '';
        this.picture_car = data.data.picture_car;
        //this.cpimgurl = "http://public.image.ywkj.online/"+data.data.picture_car;
        this.cpimgurl = data.data.picture_car;
        
      }else {
        Toast(data.message);
      }     
    },
    async next() {
      //Dialog({ message: "name："+ this.name+",idnum"+ this.idnum+",wxdj_uuid"+ this.wxdj_uuid+",picture_peo"+ this.picture_peo});
      if (isEmpty(this.picture)) {
        Toast('请正确上传近照!');
        return;
      }
      /*if (!this.rlflag) {
        Toast('请拍近照进行人脸识别!');
        return;
      }*/
      this.$router.push('/assistregister/register_3?wxdj_uuid=' + this.wxdj_uuid +'&gxy=' + this.gxy +'&tnb=' + this.tnb +'&xnxgjb=' + this.xnxgjb +'&xc=' + this.xc);
    }
  }
};
</script>

<style lang="less" scoped>
.icon1 {
  width: 64px;
  height: 64px;
  display: block;
}
.bg {
  height: 503px;
}
.content {
  margin-top: 60px;
}
.list {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  &.list2 {
    margin-top: 20px;
  }
  &.list3 {
    margin-top: 50px;
  }
  .listitem {
    overflow: hidden;
    height: 100px;
    border-radius: 8px;
    .icon {
      width: 64px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }   
    .arrow {
      float: right;
      display: block;
      margin-top: 32px;
      margin-right: 38px;
      height: 40px;
    }
    .text {
      font-size: 32px;
      float: left;
      margin-left: 20px;
      line-height: 44px;
      margin-top: 30px;
    }
    .right {
      line-height: 40px;
      color: #666666;
      font-size: 28px;
      float: right;
      margin-top: 32px;
      margin-right: 38px;
    }
    .input {
      margin-left: 15px;
      border: 0;
      color: #333;
      margin-top: 34px;
      font-size: 32px;
    }
  }
  .listitem1 {
    overflow: hidden;
    height: 270px;
    border-radius: 8px;
    display: flex;
    .listbox {
      flex: 1;
      overflow: hidden;
      .red {
        color: rgb(180, 4, 4);
      }
      .name {
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        font-size: 35px;
        font-weight:800;
        margin-top: 52px;
        margin-left: 34px;
      }
      .unames {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: #666666;
        margin-top: 30px;
        margin-left: 36px;
      }
      .unames1 {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: #666666;
        margin-top: 10px;
        margin-left: 36px;
      }
      
    }
    .listbox1 {
      flex: 1;
      overflow: hidden;
      .iconkg {
        margin-top: 34px;
        width: 320px;
        height: 203px;     
        float: right;
        margin-right: 34px;
      }

      
    }
    
  }
}
.list_ll {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  &.list2 {
    margin-top: 15px;
  }
  .ll {
    width: 90%;
    margin: 0px auto;
  }
  
}
.info {
  overflow: hidden;
  margin: 90px 54px 62px;
  img {
    width: 144px;
    height: 144px;
    border-radius: 144px;
    display: block;
    float: left;
  }
  .detail {
    overflow: hidden;
    margin-left: 44px;
    float: left;
    .name {
      margin-top: 20px;
      line-height: 66px;
      color: #fff;
      font-size: 48px;
    }
    .tel {
      height: 40px;
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
      color: rgba(255, 255, 255, 1);
      opacity: 0.6;
    }
  }
}
</style>